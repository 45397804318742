<script setup lang="ts">
import { useDisplay } from "vuetify";

const emit = defineEmits(["close"]);
const { mdAndUp } = useDisplay();
const config = useRuntimeConfig()
</script>

<template>
  <div class="megamenu-inner megamenu-margin">
    <div v-if="!mdAndUp" class="ma-3 d-flex justify-end">
      <div class="d-flex align-center" @click="emit('close')">
        <VIcon icon="mdi-chevron-down" />
      </div>
    </div>
    <VRow>
      <VCol cols="12" md="4" class="megamenu-block text-center text-md-left">
        <div class="mb-5">
          <h4 class="mb-1 font-weight-bold">
            {{ $t("navbar.developer_first_title") }}
          </h4>
          <div class="megamenu-block-list-item">
            <NuxtLink
              class="list-link"
              :to="config.public.documentationLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t("navbar.documentation") }}
            </NuxtLink>
          </div>
        </div>
        <div class="mb-5">
          <h4 class="mb-1 font-weight-bold">
            {{ $t("navbar.developer_second_title") }}
          </h4>
          <div class="megamenu-block-list-item">
            <NuxtLink
              class="list-link"
              :to="config.public.githubLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t("navbar.github") }}
            </NuxtLink>
          </div>
        </div>
        <div class="mb-5">
          <h4 class="mb-1 font-weight-bold">
            {{ $t("navbar.developer_third_title") }}
          </h4>
          <div class="megamenu-block-list-item">
            <NuxtLink
              class="list-link"
              :to="config.public.whitepaperLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ $t("navbar.whitepaper") }}
            </NuxtLink>
          </div>
        </div>
      </VCol>
    </VRow>
  </div>
</template>
